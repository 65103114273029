import { UserDataRequestBody } from './manageAccount';

const baseUrl = `https://accounts-api.${process.env.REACT_APP_DOMAIN}/identityprovider`;

const getBaseHeaders = (accessToken: string) => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`
  };
};

export const updateSSOAdminData = async (accessToken: string, body: UserDataRequestBody) => {
  const headers = getBaseHeaders(accessToken);

  const response = await fetch(baseUrl, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body)
  });

  return response;
};
