import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { CreateAccount } from './pages/createAccount';
import { Confirmation } from './pages/confirmation';
import { UnsupportedRevuVersion } from './pages/unsupportedRevuVersion';
import { ManageAccount } from './pages/manageAccount';
import { Error } from './pages/error';
import { ForgotPassword } from './pages/forgotPassword';
import { ForgotPasswordConfirmation } from './pages/forgotPasswordConfirmation';
import { ResetPassword } from './pages/resetPassword';
import { EmailVerified } from './pages/emailVerified';
import { ResetPasswordConfirmation } from './pages/resetPasswordConfirmation';
import { AuthCallback } from './pages/authCallback';
import { AuthProvider } from './contexts/auth';
import { Logout } from './pages/logout';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/createaccount" element={<CreateAccount />} />
        <Route path="/createaccount/confirmation" element={<Confirmation />} />
        <Route path="/createaccount/unsupportedrevuversion" element={<UnsupportedRevuVersion />} />
        <Route path="/error" element={<Error />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/forgotpasswordconfirmation" element={<ForgotPasswordConfirmation />} />
        <Route path="/emailverified" element={<EmailVerified />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/resetpassword/confirmation" element={<ResetPasswordConfirmation />} />
        <Route path="/logout" element={<Logout />} />
        <Route
          path="/auth"
          element={
            <AuthProvider>
              <AuthCallback />
            </AuthProvider>
          }
        />
        <Route
          path="/manageaccount"
          element={
            <AuthProvider>
              <ManageAccount />
            </AuthProvider>
          }
        />
        <Route path="*" element={<Navigate to="/manageaccount" />} />
      </Routes>
    </Router>
  );
}

export default App;
