import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericMessageContainer } from '../../components/GenericMessageContainer';
import { UsefulLinksContainer } from '../../components/UsefulLinksContainer';
import { usePreferredLanguage } from '../../hooks/usePreferredLanguage';

import * as translationConstants from '../../utils/constants/i18nTranslationConstants';

export const Error: FC = () => {
  const { t } = useTranslation();
  usePreferredLanguage();

  return (
    <GenericMessageContainer
      title={t(translationConstants.OOPS_SOMETHING_WENT_WRONG)}
      links={<UsefulLinksContainer />}
    >
      <p className="text-[14px] font-[450] pt-[5px] text-gray mb-[10px] font-sans p-7">
        {t(translationConstants.ITS_NOT_YOU_ITS_US)}
      </p>
    </GenericMessageContainer>
  );
};
