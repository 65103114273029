import { FC } from 'react';
import { LuCheck } from 'react-icons/lu';

type GreenCheckProps = {
  hidden: boolean;
  testId?: string;
};

export const GreenCheck: FC<GreenCheckProps> = ({ hidden, testId = '' }) => {
  return (
    <LuCheck size={25} className={`mr-3 text-success ${hidden && 'hidden'}`} data-testid={testId} />
  );
};
