import { LuEye } from 'react-icons/lu';

interface PasswordToggleProps {
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
  testId?: string;
}

export const PasswordToggle: React.FC<PasswordToggleProps> = ({
  showPassword,
  setShowPassword,
  testId
}) => {
  const togglePasswordVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <button type="button" onClick={togglePasswordVisibility} tabIndex={-1}>
      <LuEye
        size={27}
        className={showPassword ? 'text-primary' : 'text-[#a6a6a6]'}
        data-testid={testId}
      />
    </button>
  );
};
