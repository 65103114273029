import { useEffect, useRef, useState } from 'react';

type useHandleLogoutArgs = {
  signOut: () => void;
};

export const useHandleLogout = ({ signOut }: useHandleLogoutArgs) => {
  const logoutRef = useRef<HTMLDivElement>(null);
  const nameRef = useRef<HTMLDivElement>(null);
  const [showLogout, setShowLogout] = useState<boolean>(false);

  //Remove logout pop-up when clicking outside of it
  useEffect(() => {
    const handleClickOutsideLogout = (event: MouseEvent) => {
      if (
        logoutRef.current &&
        nameRef.current &&
        !logoutRef.current.contains(event.target as Node) &&
        !nameRef.current.contains(event.target as Node)
      ) {
        setShowLogout(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideLogout);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideLogout);
    };
  }, []);

  const handleLogoutPopUp = () => {
    setShowLogout(!showLogout);
  };

  const handleLogout = () => {
    signOut();
  };

  return { logoutRef, nameRef, showLogout, handleLogoutPopUp, handleLogout };
};
