import { FC } from 'react';

type ValidationErrorProps = {
  hidden: boolean;
  text: string | undefined;
  testId?: string;
};

export const ValidationError: FC<ValidationErrorProps> = ({ hidden, text, testId }) => {
  return (
    <span data-testid={testId} className={`mr-1 text-red-500 ${hidden && 'hidden'}`}>
      {text}
    </span>
  );
};
