// Fetch OIDC configuration
const fetchOIDCConfiguration = async () => {
  const oidcConfigUrl = `https://login.${process.env.REACT_APP_DOMAIN}/.well-known/openid-configuration`;

  const response = await fetch(oidcConfigUrl);
  if (!response.ok) {
    throw new Error(`Failed to fetch OIDC configuration: ${response.statusText}`);
  }
  return await response.json();
};

// Dynamically get the authorization, token, and end session endpoints
export const getAuthEndpoints = async () => {
  const config = await fetchOIDCConfiguration();
  return {
    AUTHORIZATION_URL: config.authorization_endpoint,
    TOKEN_URL: config.token_endpoint,
    END_SESSION_URL: config.end_session_endpoint
  };
};

export const CLIENT_ID = '7a1f4523-4d02-4ec7-9bbc-df8a90b6da94';
export const REDIRECT_URI = `${window.location.origin}/auth`;

const baseHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded'
};

export const signInRequest = async (code: string, codeVerifier: string) => {
  const { TOKEN_URL } = await getAuthEndpoints();
  try {
    const response = await fetch(TOKEN_URL, {
      method: 'POST',
      headers: baseHeaders,
      body: new URLSearchParams({
        grant_type: 'authorization_code',
        code,
        redirect_uri: REDIRECT_URI,
        client_id: CLIENT_ID,
        code_verifier: codeVerifier
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    throw new Error(`Failed to sign in: ${error}`);
  }
};

export const refreshTokensRequest = async (refreshToken: string) => {
  const { TOKEN_URL } = await getAuthEndpoints();
  try {
    const response = await fetch(TOKEN_URL, {
      method: 'POST',
      headers: baseHeaders,
      body: new URLSearchParams({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: CLIENT_ID
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    throw new Error(`Failed to refresh tokens: ${error}`);
  }
};
