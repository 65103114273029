import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from "i18next-http-backend"
 
import translationEN_GB from './assets/locales/en-GB/translation.json';
import translationEN_US from './assets/locales/en-US/translation.json';
import translationDA from './assets/locales/da/translation.json';
import translationDE from './assets/locales/de/translation.json';
import translationES from './assets/locales/es/translation.json';
import translationFI from './assets/locales/fi/translation.json';
import translationFR from './assets/locales/fr/translation.json';
import translationIT from './assets/locales/it/translation.json';
import translationJA from './assets/locales/ja/translation.json';
import translationKO from './assets/locales/ko/translation.json';
import translationNL from './assets/locales/nl/translation.json';
import translationNO from './assets/locales/no/translation.json';
import translationSV from './assets/locales/sv/translation.json';
 
const resources = {
  "en-GB":{
    translation: translationEN_GB
  },
  "en-US":{
    translation: translationEN_US
  },
  da: {
    translation: translationDA
  },
  de: {
    translation: translationDE
  },
  es: {
    translation: translationES
  },
  fi: {
    translation: translationFI
  },
  fr: {
    translation: translationFR
  },
  it: {
    translation: translationIT
  },
  ja: {
    translation: translationJA
  },
  ko: {
    translation: translationKO
  },
  nl: {
    translation: translationNL
  },
  no: {
    translation: translationNO
  },
  sv: {
    translation: translationSV
  },
};
 
 
const options = {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng'
  }
 
i18n
  .use(XHR)
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    fallbackLng: 'en-US',
    debug: false,
    resources,
    interpolation: {
      escapeValue: false,
    }
  });
 
export default i18n;