export type ForgotPasswordRequestBody = {
  email: string;
};

export type ResetPasswordRequestBody = {
  email: string;
  password: string;
  code: string;
};

const baseUrl = `https://accounts-api.${process.env.REACT_APP_DOMAIN}`;

const baseHeaders = {
  'content-type': 'application/json'
};

export const forgotPassword = async (email: string) => {
  const body: ForgotPasswordRequestBody = { email: email };

  const response = await fetch(`${baseUrl}/forgotpassword`, {
    method: 'POST',
    headers: baseHeaders,
    body: JSON.stringify(body)
  });

  return response;
};

export const resetPassword = async (email: string, password: string, code: string) => {
  const body: ResetPasswordRequestBody = { email: email, password: password, code: code };

  const response = await fetch(`${baseUrl}/resetpassword`, {
    method: 'POST',
    headers: baseHeaders,
    body: JSON.stringify(body)
  });

  return response;
};
