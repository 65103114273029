import { FC, useEffect, useState } from 'react';
import { GenericMessageContainer } from '../../components/GenericMessageContainer';
import { getSessionCookie } from '../../utils/cookies';
import { Link } from 'react-router-dom';

import * as urlConstants from '../../utils/constants/urlConstants';
import * as translationConstants from '../../utils/constants/i18nTranslationConstants';

export const UnsupportedRevuVersion: FC = () => {
  const [signInLink, setSignInLink] = useState('/manageaccount');

  useEffect(() => {
    const returnUrl = getSessionCookie(urlConstants.RETURN_URL);
    if (returnUrl) {
      setSignInLink(returnUrl);
    }
  }, []);

  return (
    <GenericMessageContainer>
      <div className="mb-[20px]">
        <p className="text-[14px] font-[450] pt-[5px] text-gray font-sans p-7">
          {translationConstants.UNSUPPORTED_REVU_VERSION}
        </p>
        <Link
          to={signInLink}
          className="mt-[20px] mb-[30px] px-7 font-[450] underline text-[#6e6e78] text-[14px]"
        >
          {translationConstants.ALREADY_REGISTERED_FULL}
        </Link>
      </div>
    </GenericMessageContainer>
  );
};
