import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericMessageContainer } from '../../components/GenericMessageContainer';
import { UsefulLinksContainer } from '../../components/UsefulLinksContainer';
import { usePreferredLanguage } from '../../hooks/usePreferredLanguage';

import * as translationConstants from '../../utils/constants/i18nTranslationConstants';

export const ResetPasswordConfirmation: FC = () => {
  const { t } = useTranslation();
  usePreferredLanguage();

  return (
    <GenericMessageContainer
      title={t(translationConstants.PASSWORD_SUCCESSFULLY_CHANGED)}
      links={<UsefulLinksContainer />}
    />
  );
};
