import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import * as translationConstants from '../../utils/constants/i18nTranslationConstants';

type ProfileSettingsRowProps = {
  name: string;
  value: string;
  handleChangeButtonClick: () => void;
  isEmailConfirmed?: boolean;
  isSSO?: boolean;
  changeButtonTestId?: string;
  handleEmailUpdatedLinkButtonClick?: () => void;
};

export const NAME_SETTINGS_ROW = 'nameRow';
export const EMAIL_SETTINGS_ROW = 'emailRow';
export const DISPLAY_NAME_SETTINGS_ROW = 'displayNameRow';
export const PASSWORD_SETTINGS_ROW = 'passwordRow';
export const SSO_IDENTITY_PROVIDER_ROW = 'ssoIdentityProviderRow';
export const BB_PREFERRED_LANGUAGE_ROW = 'bbPreferredLanguageRow';

export const ProfileSettingsRow: FC<ProfileSettingsRowProps> = ({
  name,
  value,
  handleChangeButtonClick,
  isEmailConfirmed,
  isSSO,
  changeButtonTestId,
  handleEmailUpdatedLinkButtonClick
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between mb-5 ">
      <div className=" flex flex-row w-full sm:flex-col sm:min-w-[100px]">
        <p className="w-1/3 font-roboto text-[16px] text-gray font-[525] leading-[1.50] tracking-[.27px]">
          {name}
        </p>
        <div className="w-2/3">
          <p className="font-roboto text-[14px] text-gray font-[400] leading-[1.71] tracking-[.23px] sm:truncate">
            {value}
          </p>
          {!isEmailConfirmed && name === t(translationConstants.EMAIL) && (
            <>
              <p className="w-[95%] font-roboto text-[14px] text-neutral font-[400] leading-[1.71] tracking-[.23px] sm:w-full">
                {t(translationConstants.PLEASE_VERIFY_EMAIL)}
              </p>
              <button
                className="font-roboto text-[14px] text-primary text-start"
                onClick={handleEmailUpdatedLinkButtonClick}
              >
                {t(translationConstants.EMAIL_ME_UPDATED_LINK)}
              </button>
            </>
          )}
        </div>
      </div>
      <button
        onClick={handleChangeButtonClick}
        className={`font-roboto text-[14px] self-start ${isEmailConfirmed === false || isSSO === true ? 'text-neutral' : 'text-primary'}`}
        data-testid={changeButtonTestId}
        disabled={isEmailConfirmed === false || isSSO === true}
      >
        {t(translationConstants.CHANGE)}
      </button>
    </div>
  );
};
