import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericMessageContainer } from '../../components/GenericMessageContainer';
import { UsefulLinksContainer } from '../../components/UsefulLinksContainer';

import * as translationConstants from '../../utils/constants/i18nTranslationConstants';
import * as urlConstants from '../../utils/constants/urlConstants';

export const Logout: FC = () => {
  const [hideLinks, setHideLinks] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const hideLinksParam = queryParams.get(urlConstants.HIDE_LINKS);
    if (hideLinksParam && hideLinksParam.toLowerCase() === 'true') {
      setHideLinks(true);
    }
  }, []);

  return (
    <GenericMessageContainer
      title={t(translationConstants.LOGGED_OUT)}
      {...(!hideLinks && { links: <UsefulLinksContainer /> })}
    />
  );
};
