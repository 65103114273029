import { FC, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { GenericMessageContainer } from '../../components/GenericMessageContainer';
import { getSessionCookie } from '../../utils/cookies';
import { Link } from 'react-router-dom';

import * as translationConstants from '../../utils/constants/i18nTranslationConstants';
import * as urlConstants from '../../utils/constants/urlConstants';

export const Confirmation: FC = () => {
  const { t } = useTranslation();
  const [signInLink, setSignInLink] = useState('/manageaccount');

  useEffect(() => {
    const returnUrl = getSessionCookie(urlConstants.RETURN_URL);
    if (returnUrl) {
      setSignInLink(returnUrl);
    }
  }, []);

  return (
    <GenericMessageContainer>
      <div className="p-7 pt-0">
        <h2 className="mb-[10px] font-roboto text-[28px] font-[650] leading-[30px] text-gray xs:mt-[50px]">
          1.{t(translationConstants.CHECK_YOUR_INBOX)}
        </h2>
        <p className="text-[14px] font-[450] pt-[5px] pb-[20px] text-gray">
          {t(translationConstants.CONFIRM_YOUR_EMAIL)}
        </p>
        <h2 className="mt-[20px] mb-[10px] font-roboto text-[28px] font-[650] leading-[30px]">
          2.
          <Trans
            i18nKey={translationConstants.THEN_SIGN_IN_HERE}
            components={{
              1: <Link to={signInLink} className="text-primary underline" data-testid="login-URL" />
            }}
          />
        </h2>
      </div>
    </GenericMessageContainer>
  );
};
