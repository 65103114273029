export const PASSWORD_CHECK_ICON = 'passwordCheckIcon';
export const SHOW_PASSWORD_BUTTON = 'showPasswordButton';
export const PASSWORD_INPUT_FIELD = 'passwordInputField';
export const NEW_PASSWORD_INPUT_FIELD = 'newPasswordInputField';
export const CURRENT_PASSWORD_INPUT_FIELD = 'currentPasswordInputField';
export const NEW_CONFIRM_PASSWORD_INPUT_FIELD = 'newConfirmPasswordInputField';
export const PASSWORD_ERROR_SPAN = 'passwordErrorSpan';
export const CREATE_ACCOUNT_BUTTON = 'createAccountButton';
export const TERMS_OF_USE_MODAL = 'termsOfUseModal';
export const MODAL_CANCEL_BUTTON = 'termsOfUseModalCancelButton';
export const MODAL_CLOSE_BUTTON = 'termsOfUseModalCloseButton';
export const MODAL_SAVE_BUTTON = 'modalSaveButton';

//Profile
export const CHANGE_NAME_BUTTON = 'changeNameButton';
export const CHANGE_DISPLAY_NAME_BUTTON = 'changeDisplayNameButton';
export const CHANGE_SSO_IDENTITY_PROVIDER_BUTTON = 'changeSSOIdentityProviderButton';
export const CHANGE_EMAIL_BUTTON = 'changeEmailButton';
export const CHANGE_PASSWORD_BUTTON = 'changePasswordButton';
export const CHANGE_PREFERRED_LANGUAGE_BUTTON = 'changePreferredLanguageButton';
export const MODAL_PREFERRED_LANGUAGE_TITLE = 'modalPreferredLanguageTitle';
export const MODAL_SSO_IDENTITY_PROVIDER_TITLE = 'modalSSOIdentityProviderTitle';
export const MODAL_DISPLAY_NAME_TITLE = 'modalDisplayNameTitle';
export const MODAL_PASSWORD_TITLE = 'modalPasswordTitle';
export const MODAL_EMAIL_TITLE = 'modalEmailTitle';
export const MODAL_BACKGROUND = 'modalBackground';
export const MESSAGE_POPUP = 'messagePopUp';
export const NAME_DIV = 'nameDiv';

//Dropdown
export const DROPDOWN_BUTTON = 'dropdown-button';

//Confirmation
export const LOGIN_URL = 'login-URL';

//Generic Message
export const BB_LOGO = 'BB-logo';
