import React, { useState, useRef, useEffect } from 'react';
import { FaChevronDown, FaCheck } from 'react-icons/fa';

import * as testConstants from '../../utils/constants/testHTMLAttributeConstants';

interface Option {
  label: string;
  value: string;
  isHidden?: boolean;
}

interface DropdownProps {
  options: Option[];
  value?: string;
  onChange: (value: string) => void;
  className?: string;
}

export const DropdownField: React.FC<DropdownProps> = ({ options, value, onChange, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (selectedValue: string) => {
    onChange(selectedValue);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`relative inline-block ${className}`} ref={dropdownRef}>
      <div
        className="p-3 flex items-center justify-between border rounded bg-white font-[450] cursor-pointer border-neutral"
        onClick={() => setIsOpen(!isOpen)}
        data-testid={testConstants.DROPDOWN_BUTTON}
      >
        <span>{options.find((option) => option.value === value)?.label || 'Select an option'}</span>
        <FaChevronDown className={`transition-transform transform ${isOpen ? 'rotate-180' : ''}`} />
      </div>
      {isOpen && (
        <ul className="absolute z-10 w-full bg-white font-[450] border rounded shadow-lg max-h-[200px] overflow-y-auto">
          {options
            .filter((option) => !option.isHidden)
            .map((option) => (
              <li
                key={option.value}
                className="flex items-center justify-between p-2 hover:bg-[#e0ecf9] cursor-pointer"
                onClick={() => handleSelect(option.value)}
              >
                <span>{option.label}</span>
                {value === option.value && <FaCheck className="text-primary ml-2" />}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};
