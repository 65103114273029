import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as fieldNameConstants from '../../utils/constants/createAccountFormConstants';
import * as translationConstants from '../../utils/constants/i18nTranslationConstants';
import * as formConstants from '../../utils/constants/createAccountFormConstants';

export type FieldValue = {
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  password: string;
  confirmPassword: string;
  currentPassword: string;
  newPassword: string;
  newConfirmPassword: string;
  customerName: string;
  clientId: string;
  clientSecret: string;
  issuer: string;
  domains: string;
};

type FieldActivityStatus = {
  firstName: boolean;
  lastName: boolean;
  displayName: boolean;
  email: boolean;
  password: boolean;
  confirmPassword: boolean;
  currentPassword: boolean;
  newPassword: boolean;
  newConfirmPassword: boolean;
  customerName: boolean;
  clientId: boolean;
  clientSecret: boolean;
  issuer: boolean;
  domains: boolean;
};

type FormState = {
  formValues: FieldValue;
  formErrors: FieldValue;
  formFieldsAreActive: FieldActivityStatus;
};

export const useValidateForm = () => {
  const [formState, setFormState] = useState<FormState>({
    formValues: {
      firstName: '',
      lastName: '',
      displayName: '',
      email: '',
      password: '',
      confirmPassword: '',
      currentPassword: '',
      newPassword: '',
      newConfirmPassword: '',
      customerName: '',
      clientId: '',
      clientSecret: '',
      issuer: '',
      domains: ''
    },
    formErrors: {
      firstName: '',
      lastName: '',
      displayName: '',
      email: '',
      password: '',
      confirmPassword: '',
      currentPassword: '',
      newPassword: '',
      newConfirmPassword: '',
      customerName: '',
      clientId: '',
      clientSecret: '',
      issuer: '',
      domains: ''
    },
    formFieldsAreActive: {
      firstName: false,
      lastName: false,
      displayName: false,
      email: false,
      password: false,
      confirmPassword: false,
      currentPassword: false,
      newPassword: false,
      newConfirmPassword: false,
      customerName: false,
      clientId: false,
      clientSecret: false,
      issuer: false,
      domains: false
    }
  });

  const { formValues, formErrors, formFieldsAreActive } = formState;

  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    for (const fieldName in formValues) {
      if (Object.prototype.hasOwnProperty.call(formValues, fieldName)) {
        if (formFieldsAreActive[fieldName as keyof FieldActivityStatus]) {
          validate(fieldName as keyof FieldValue)();
        }
      }
    }
  }, [formValues]);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&'()*+,-./\\:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!#$%&'()*+,-./\\:;<=>?@[\\\]^_`{|}~]{8,32}$/;

  const validateField = (
    fieldName: string,
    value: string,
    validationFn: (val: string) => boolean,
    isRequiredErrorMessage: string,
    errorMessage: string
  ) => {
    if (value === '') {
      handleError(fieldName, isRequiredErrorMessage);
    } else if (validationFn(value)) {
      handleError(fieldName, errorMessage);
    } else {
      handleError(fieldName, '');
    }
  };

  const handleError = (fieldName: string, error: string) => {
    setFormState((prevState) => ({
      ...prevState,
      formErrors: { ...prevState.formErrors, [fieldName]: error }
    }));
  };

  const validate = (fieldName: keyof FieldValue) => () => {
    const value = formValues[fieldName].trim();

    switch (fieldName) {
      case fieldNameConstants.FIRST_NAME_FIELD:
        validateField(
          fieldName,
          value,
          (val) => val.length > 50,
          t(translationConstants.FIELD_IS_REQUIRED, { what: t(translationConstants.FIRST_NAME) }),
          t(translationConstants.FIRST_NAME_MAX_CHARS)
        );
        break;
      case fieldNameConstants.LAST_NAME_FIELD:
        validateField(
          fieldName,
          value,
          (val) => val.length > 50,
          t(translationConstants.FIELD_IS_REQUIRED, { what: t(translationConstants.LAST_NAME) }),
          t(translationConstants.LAST_NAME_MAX_CHARS)
        );
        break;
      case fieldNameConstants.DISPLAY_NAME_FIELD:
        validateField(
          fieldName,
          value,
          (val) => val.length < 3 || val.length > 50,
          t(translationConstants.FIELD_IS_REQUIRED, { what: t(translationConstants.DISPLAY_NAME) }),
          t(translationConstants.DISPLAY_NAME_CHAR_RANGE)
        );
        break;
      case fieldNameConstants.EMAIL_FIELD:
        validateField(
          fieldName,
          value,
          (val) => !emailRegex.test(val),
          t(translationConstants.FIELD_IS_REQUIRED, { what: t(translationConstants.EMAIL) }),
          t(translationConstants.ENTER_VALID_EMAIL)
        );
        setIsEmailValid(emailRegex.test(value));
        break;
      case fieldNameConstants.PASSWORD_FIELD:
        validateField(
          fieldName,
          value,
          (val) => !passwordRegex.test(val),
          t(translationConstants.FIELD_IS_REQUIRED, { what: t(translationConstants.PASSWORD) }),
          t(translationConstants.ENTER_VALID_PASSWORD)
        );
        setIsPasswordValid(passwordRegex.test(value));
        break;
      case fieldNameConstants.CONFIRM_PASSWORD_FIELD:
        if (value === '') {
          handleError(
            fieldName,
            t(translationConstants.FIELD_IS_REQUIRED, {
              what: t(translationConstants.CONFIRM_PASSWORD)
            })
          );
        } else if (!passwordRegex.test(value)) {
          handleError(fieldName, t(translationConstants.ENTER_VALID_CONFIRM_PASSWORD));
        } else if (value !== formValues.password) {
          handleError(fieldName, t(translationConstants.CONFIRM_PASSWORD_MISMATCH));
        } else {
          handleError(fieldName, '');
        }
        break;
      case fieldNameConstants.CURRENT_PASSWORD_FIELD:
        validateField(
          fieldName,
          value,
          (val) => !passwordRegex.test(val),
          t(translationConstants.FIELD_IS_REQUIRED, {
            what: t(translationConstants.CURRENT_PASSWORD)
          }),
          t(translationConstants.ENTER_VALID_PASSWORD)
        );
        break;
      case fieldNameConstants.NEW_PASSWORD_FIELD:
        if (value === '') {
          handleError(
            fieldName,
            t(translationConstants.FIELD_IS_REQUIRED, {
              what: t(translationConstants.NEW_PASSWORD)
            })
          );
        } else if (!passwordRegex.test(value)) {
          handleError(fieldName, t(translationConstants.ENTER_VALID_PASSWORD));
        } else if (value == formValues.password) {
          handleError(fieldName, t(translationConstants.NEW_PASSWORD_CANNOT_BE_SAME_AS_OLD));
        } else {
          handleError(fieldName, '');
        }
        break;
      case fieldNameConstants.NEW_CONFIRM_PASSWORD_FIELD:
        if (value === '') {
          handleError(
            fieldName,
            t(translationConstants.FIELD_IS_REQUIRED, {
              what: t(translationConstants.CONFIRM_NEW_PASSWORD)
            })
          );
        } else if (value !== formValues.newPassword) {
          handleError(fieldName, t(translationConstants.CONFIRM_PASSWORD_MISMATCH));
        } else {
          handleError(fieldName, '');
        }
        break;
      case fieldNameConstants.CUSTOMER_NAME_FIELD:
        validateField(
          fieldName,
          value,
          (val) => val.length < 3 || val.length > 50,
          t(translationConstants.FIELD_IS_REQUIRED, {
            what: t(translationConstants.CUSTOMER_NAME)
          }),
          t(translationConstants.CUSTOMER_NAME_CHAR_RANGE)
        );
        break;
      case fieldNameConstants.CLIENT_ID_FIELD:
        validateField(
          fieldName,
          value,
          (val) => val.length < 1 || val.length > 256,
          t(translationConstants.FIELD_IS_REQUIRED, { what: t(translationConstants.CLIENT_ID) }),
          t(translationConstants.CHAR_MAX_256)
        );
        break;
      case fieldNameConstants.CLIENT_SECRET_FIELD:
        validateField(
          fieldName,
          value,
          (val) => val.length < 1 || val.length > 256,
          t(translationConstants.FIELD_IS_REQUIRED, {
            what: t(translationConstants.CLIENT_SECRET)
          }),
          t(translationConstants.CHAR_MAX_256)
        );
        break;
      case fieldNameConstants.ISSUER_FIELD:
        validateField(
          fieldName,
          value,
          (val) => val.length < 1 || val.length > 2048,
          t(translationConstants.FIELD_IS_REQUIRED, { what: t(translationConstants.ISSUER) }),
          t(translationConstants.CHAR_MAX_2048)
        );
        break;
      case fieldNameConstants.DOMAINS_FIELD:
        validateField(
          fieldName,
          value,
          (val) => val.length < 1 || val.length > 2048,
          t(translationConstants.FIELD_IS_REQUIRED, { what: t(translationConstants.DOMAINS) }),
          t(translationConstants.CHAR_MAX_2048)
        );
        break;
      default:
        break;
    }
  };

  const handleChange =
    (fieldName: keyof FieldValue) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormState((prevState) => ({
        ...prevState,
        formValues: { ...prevState.formValues, [fieldName]: e.target.value }
      }));
      if (!formFieldsAreActive[fieldName]) {
        setFormState((prevState) => ({
          ...prevState,
          formFieldsAreActive: { ...prevState.formFieldsAreActive, [fieldName]: true }
        }));
      }
    };

  const validateAllFields = () => {
    //Set all input fields to active so they can be validated
    setFormFieldsAreActive({
      firstName: true,
      lastName: true,
      displayName: true,
      email: true,
      password: true,
      confirmPassword: true,
      currentPassword: true,
      newPassword: true,
      newConfirmPassword: true,
      customerName: true,
      clientId: true,
      clientSecret: true,
      issuer: true,
      domains: true
    });

    for (const fieldName in formValues) {
      if (Object.prototype.hasOwnProperty.call(formValues, fieldName)) {
        validate(fieldName as keyof FieldValue)();
      }
    }
  };

  const setEmailAlreadyExistsError = () => {
    handleError(
      formConstants.EMAIL_FIELD,
      t(translationConstants.EMAIL_ALREADY_EXISTS, { where: '' })
    );
  };

  const setCurrentPasswordNotCorrectError = () => {
    handleError(
      fieldNameConstants.CURRENT_PASSWORD_FIELD,
      t(translationConstants.CURRENT_PASSWORD_NOT_CORRECT)
    );
  };

  const SSOFormValuesTranslationKeys = {
    ...formValues,
    customerName: translationConstants.CUSTOMER_NAME,
    clientId: translationConstants.CLIENT_ID,
    clientSecret: translationConstants.CLIENT_SECRET,
    issuer: translationConstants.ISSUER,
    domains: translationConstants.DOMAINS
  };

  // uses the parameter field from the 400 Response to generate an error message
  const setSSOFieldNotValidError = (fieldName: keyof FieldValue) => {
    const translationKey = SSOFormValuesTranslationKeys[fieldName];
    const localizedFieldName = t(translationKey);

    handleError(
      fieldName,
      t(translationConstants.FIELD_IS_NOT_VALID, { fieldName: localizedFieldName })
    );
  };

  const areSpecificFormFieldsValid = (fields: (keyof FieldValue)[]): boolean => {
    //Validate fields
    for (const fieldName in formValues) {
      if (
        Object.prototype.hasOwnProperty.call(formValues, fieldName) &&
        fields.includes(fieldName as keyof FieldValue)
      ) {
        validate(fieldName as keyof FieldValue)();
      }
    }

    //Prevent user from submitting the empty change password or empty SSO Identity provider fields
    if (
      fields.includes(fieldNameConstants.NEW_PASSWORD_FIELD) ||
      fields.includes(fieldNameConstants.ISSUER_FIELD)
    ) {
      if (fields.some((field) => formFieldsAreActive[field] === false)) {
        return false;
      }
    }

    return fields.every((field) => formErrors[field] === '');
  };

  const setFormValues = (values: FieldValue) => {
    setFormState((prevState) => ({
      ...prevState,
      formValues: { ...prevState.formValues, ...values }
    }));
  };

  const setFormErrors = (values: FieldValue) => {
    setFormState((prevState) => ({
      ...prevState,
      formErrors: { ...prevState.formErrors, ...values }
    }));
  };

  const setFormFieldsAreActive = (values: FieldActivityStatus) => {
    setFormState((prevState) => ({
      ...prevState,
      formFieldsAreActive: { ...prevState.formFieldsAreActive, ...values }
    }));
  };

  const hasFormErrors = (): boolean => {
    return Object.values(formErrors).some((error) => error !== '');
  };

  // Remove validation errors from password fields, as they are intended to be empty
  const resetPasswordFields = () => {
    setFormValues({
      ...formValues,
      newPassword: '',
      currentPassword: '',
      newConfirmPassword: '',
      customerName: '',
      clientId: '',
      clientSecret: '',
      issuer: '',
      domains: ''
    });

    setFormErrors({
      ...formErrors,
      newPassword: '',
      currentPassword: '',
      newConfirmPassword: '',
      customerName: '',
      clientId: '',
      clientSecret: '',
      issuer: '',
      domains: ''
    });

    setFormFieldsAreActive({
      ...formFieldsAreActive,
      newPassword: false,
      currentPassword: false,
      newConfirmPassword: false,
      customerName: false,
      clientId: false,
      clientSecret: false,
      issuer: false,
      domains: false
    });
  };

  return {
    formValues,
    setFormValues,
    formErrors,
    setFormErrors,
    formFieldsAreActive,
    setFormFieldsAreActive,
    handleChange,
    validate,
    isPasswordValid,
    isEmailValid,
    setIsEmailValid,
    validateAllFields,
    setEmailAlreadyExistsError,
    setSSOFieldNotValidError,
    areSpecificFormFieldsValid,
    setCurrentPasswordNotCorrectError,
    hasFormErrors,
    resetPasswordFields
  };
};
