export const FIRST_NAME_FIELD = 'firstName';
export const LAST_NAME_FIELD = 'lastName';
export const DISPLAY_NAME_FIELD = 'displayName';
export const EMAIL_FIELD = 'email';
export const PASSWORD_FIELD = 'password';
export const CONFIRM_PASSWORD_FIELD = 'confirmPassword';
export const CURRENT_PASSWORD_FIELD = 'currentPassword';
export const NEW_PASSWORD_FIELD = 'newPassword';
export const NEW_CONFIRM_PASSWORD_FIELD = 'newConfirmPassword';

//Fields for SSO Admin:
export const IDP_TYPE = 'idpType';
export const CUSTOMER_NAME_FIELD = 'customerName';
export const CLIENT_ID_FIELD = 'clientId';
export const CLIENT_SECRET_FIELD = 'clientSecret';
export const ISSUER_FIELD = 'issuer';
export const DOMAINS_FIELD = 'domains';
export const PREFERRED_LANGUAGE_FIELD = 'preferredLanguage';
export const TOOLTIP_LABEL = '(?)';
