import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as translationConstants from '../../utils/constants/i18nTranslationConstants';
import * as linksConstants from '../../utils/constants/usefulLinksConstants';

export const UsefulLinksContainer: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="py-[22px] px-[28px] font-sans text-[16px] font-[450] leading-[30px]">
      <div>
        <Link to="/manageaccount" className="text-primary hover:underline">
          {t(translationConstants.LINK_OUT_MANAGE_ACCOUNT)}
        </Link>
      </div>
      <div>
        <a
          href={linksConstants.BLUEBEAM_LINK}
          className="text-primary hover:underline"
          rel="noopener noreferrer"
        >
          {t(translationConstants.LINK_OUT_BLUEBEAM)}
        </a>
      </div>
      <div>
        <a
          href={linksConstants.GATEWAY_LINK}
          className="text-primary hover:underline"
          rel="noopener noreferrer"
        >
          {t(translationConstants.LINK_OUT_GATEWAY)}
        </a>
      </div>
      <div>
        <a
          href={linksConstants.CLOUD_LINK}
          className="text-primary hover:underline"
          rel="noopener noreferrer"
        >
          {t(translationConstants.LINK_OUT_CLOUD)}
        </a>
      </div>
      <div>
        <a
          href={linksConstants.WEBSTORE_LINK}
          className="text-primary hover:underline"
          rel="noopener noreferrer"
        >
          {t(translationConstants.LINK_OUT_WEBSTORE)}
        </a>
      </div>
      <div>
        <a
          href={linksConstants.SUPPORT_LINK}
          className="text-primary hover:underline"
          rel="noopener noreferrer"
        >
          {t(translationConstants.LINK_OUT_SUPPORT)}
        </a>
      </div>
      <div>
        <a
          href={linksConstants.CONTACT_LINK}
          className="text-primary hover:underline"
          rel="noopener noreferrer"
        >
          {t(translationConstants.LINK_OUT_CONTACT)}
        </a>
      </div>
    </div>
  );
};
