import { useEffect, useRef } from 'react';
import { useAuth } from '../../contexts/auth';
import { Loading } from '../../components/Loading';

export const AuthCallback = () => {
  const { handleSignInCallback } = useAuth();
  const hasRun = useRef(false);

  useEffect(() => {
    if (!hasRun.current) {
      handleSignInCallback();
      hasRun.current = true;
    }
  }, []);

  return <Loading />;
};
