import { GeneralIcons } from '@bluebeam/react-skyline-art';

export const Loading = () => {
  return (
    <div className="flex w-full h-screen justify-center items-center">
      <GeneralIcons.ProgressIcon
        className="w-[5%] h-[5%] sm:w-[15%] sm:h-[15%] text-primary"
        size={'44px'}
      />
    </div>
  );
};
