import { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { GenericMessageContainer } from '../../components/GenericMessageContainer';

import * as translationConstants from '../../utils/constants/i18nTranslationConstants';

export const ForgotPasswordConfirmation: FC = () => {
  const { t } = useTranslation();

  const moreInfoUrl = 'https://support.bluebeam.com/articles/bluebeam-id';

  return (
    <GenericMessageContainer title={t(translationConstants.CHECK_YOUR_EMAIL)}>
      <div className="p-7 pt-0">
        <p className="text-[14px] font-[450] pt-[5px] pb-[20px] text-gray">
          {t(translationConstants.FORGOT_PASSWORD_SUCCESS)}
        </p>
        <p className="text-[14px] font-[450] pt-[5px] pb-[20px] text-gray">
          <Trans
            i18nKey={translationConstants.FORGOT_PASSWORD_SUCCESS_SPAM}
            components={{
              1: <a href={moreInfoUrl} className="text-primary underline" />
            }}
          />
        </p>
      </div>
    </GenericMessageContainer>
  );
};
