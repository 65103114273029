import { useEffect, useState } from 'react';

export const useHandlePopUp = () => {
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [popUpMessage, setPopUpMessage] = useState<string>('');
  const [popUpColor, setPopUpColor] = useState<string>('bg-green-100');

  // Remove popup after 4 seconds
  useEffect(() => {
    if (showPopUp) {
      const timer = setTimeout(() => {
        setShowPopUp(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [showPopUp]);

  const handleClosePopUp = () => {
    setShowPopUp(false);
  };

  return {
    showPopUp,
    setShowPopUp,
    popUpMessage,
    setPopUpMessage,
    handleClosePopUp,
    popUpColor,
    setPopUpColor
  };
};
