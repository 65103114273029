import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { GenericMessageContainer } from '../../components/GenericMessageContainer';
import { Button } from '@bluebeam/react-skyline-button';
import { Label } from '@bluebeam/react-skyline-label';
import { CgSpinner } from 'react-icons/cg';
import { FormInput } from '../../components/FormInput';
import { ValidationError } from '../../components/ValidationError';
import { useValidateForm } from '../../hooks/useValidateForm';
import { forgotPassword } from '../../services/password';

import * as formConstants from '../../utils/constants/createAccountFormConstants';
import * as translationConstants from '../../utils/constants/i18nTranslationConstants';

export const ForgotPassword: FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    formValues,
    formErrors,
    handleChange,
    validateAllFields,
    areSpecificFormFieldsValid,
    formFieldsAreActive
  } = useValidateForm();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    validateAllFields();
    if (
      areSpecificFormFieldsValid([formConstants.EMAIL_FIELD]) &&
      formFieldsAreActive[formConstants.EMAIL_FIELD]
    ) {
      try {
        const response = await forgotPassword(formValues[formConstants.EMAIL_FIELD]);
        if (response.ok) {
          navigate('/forgotpasswordconfirmation');
        } else {
          navigate('/error');
        }
      } catch (error) {
        navigate('/error');
      }
    }
    setIsLoading(false);
  };

  return (
    <GenericMessageContainer title={t(translationConstants.RESET_PASSWORD)}>
      <form onSubmit={handleSubmit} className="flex flex-col p-7 pt-0">
        <div className="flex flex-col">
          <Label className="text-gray">
            <Trans
              i18nKey={translationConstants.BLUEBEAM_ID_YOUR_EMAIL_ADDRESS}
              components={{
                1: <span className="font-200 font-normal" />
              }}
            />
          </Label>
          <FormInput
            placeholder={t(translationConstants.EMAIL_EXAMPLE)}
            value={formValues.email}
            onChange={handleChange(formConstants.EMAIL_FIELD)}
            hasError={formErrors.email != ''}
          />
          <ValidationError hidden={!formErrors.email} text={formErrors.email} />
        </div>
        <Button
          className={`mt-8 px-6 py-2 self-center bg-primary rounded-[0.25rem] w-[80%] h-fit text-white text-[16px] font-[650] capitalize sm:min-h-[4rem] ${
            isLoading ? '' : 'hover:bg-[#286090]'
          }`}
          variant="filled"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <CgSpinner className="animate-spin mx-auto" size={24} />
          ) : (
            t(translationConstants.SEND_RESET_PASSWORD_INSTRUCTIONS)
          )}
        </Button>
      </form>
    </GenericMessageContainer>
  );
};
