import { useEffect, useState } from 'react';
import { FieldValue } from '../../useValidateForm';
import { getUserAccountData } from '../../../services/manageAccount';
import { useNavigate } from 'react-router-dom';
import i18n from '../../../i18n';

type useHandleGetUserDataArgs = {
  formValues: FieldValue;
  setFormValues: (values: FieldValue) => void;
  isUserAuthenticated: () => boolean;
  signIn: () => void;
  bbid: string | undefined;
  accessToken: string | null;
  removeCredentials: () => void;
};

export const useHandleGetUserData = ({
  formValues,
  setFormValues,
  isUserAuthenticated,
  signIn,
  bbid,
  accessToken,
  removeCredentials
}: useHandleGetUserDataArgs) => {
  const [profileValues, setProfileValues] = useState<FieldValue>({
    ...formValues
  });
  const [isEmailConfirmed, setIsEmailConfirmed] = useState<boolean>(false);
  const [isSSOAdmin, setisSSOAdmin] = useState<boolean>(false);
  const [isSSO, setisSSO] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [preferredLanguage, setPreferredLanguage] = useState<string>('en-US');

  const navigate = useNavigate();

  useEffect(() => {
    if (!isUserAuthenticated()) {
      signIn();
    } else {
      fetchUserData();
    }
  }, []);

  const fetchUserData = async () => {
    try {
      const userData = await getUserAccountData(bbid!, accessToken!);
      const fetchedProfileValues = {
        ...profileValues,
        firstName: userData.firstName,
        lastName: userData.lastName,
        displayName: userData.displayName,
        email: userData.email
      };

      setIsEmailConfirmed(userData.emailConfirmed);
      setisSSOAdmin(userData.isSSOAdmin);
      setisSSO(userData.isSSO);

      if (userData.bb_preferred_language) {
        setPreferredLanguage(userData.bb_preferred_language);
      }

      setProfileValues(fetchedProfileValues);
      setFormValues(fetchedProfileValues);

      await i18n.changeLanguage(userData.bb_preferred_language);
    } catch (error) {
      removeCredentials();
      navigate('/error');
    } finally {
      setIsLoaded(true);
    }
  };

  return {
    profileValues,
    setProfileValues,
    isEmailConfirmed,
    isSSOAdmin,
    isSSO,
    preferredLanguage,
    setPreferredLanguage,
    isLoaded
  };
};
