export type UserDataResponseBody = {
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  bbid: string;
  accountStatus: string;
  isSSO: boolean;
  isSSOAdmin: boolean;
  emailConfirmed: boolean;
  bb_preferred_language: string;
};

export type UserDataRequestBody = {
  firstName?: string;
  lastName?: string;
  displayName?: string;
  email?: string;
  password?: string;
  oldPassword?: string;
  resendEmailConfirmation?: boolean;
  idpType?: string;
  customerName?: string;
  clientId?: string;
  clientSecret?: string;
  issuer?: string;
  domains?: string[];
  bb_preferred_language?: string;
};

const baseUrl = `https://accounts-api.${process.env.REACT_APP_DOMAIN}/account`;

const getBaseHeaders = (accessToken: string) => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`
  };
};

export const getUserAccountData = async (bbid: string, accessToken: string) => {
  const url = `${baseUrl}/${bbid}`;
  const headers = getBaseHeaders(accessToken);

  const response = await fetch(url, {
    method: 'GET',
    headers: headers
  });

  if (!response.ok) {
    throw new Error('user is unathorized');
  }

  const responseData: UserDataResponseBody = await response.json();

  return responseData;
};

export const updateUserAccountData = async (
  bbid: string,
  accessToken: string,
  body: UserDataRequestBody
) => {
  const url = `${baseUrl}/${bbid}`;
  const headers = getBaseHeaders(accessToken);

  const response = await fetch(url, {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify(body)
  });

  return response;
};

export const resendConfirmationEmail = async (bbid: string, accessToken: string) => {
  const url = `${baseUrl}/${bbid}`;
  const headers = getBaseHeaders(accessToken);
  const body: UserDataRequestBody = { resendEmailConfirmation: true };

  const response = await fetch(url, {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify(body)
  });

  return response;
};
