import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@bluebeam/react-skyline-button';
import { CgSpinner } from 'react-icons/cg';

import * as testConstants from '../../../utils/constants/testHTMLAttributeConstants';
import * as translationConstants from '../../../utils/constants/i18nTranslationConstants';

type ModalProps = {
  onClose: () => void;
  children?: React.ReactNode;
  isLoading: boolean;
  isDisabled: boolean;
};

export const Modal: FC<ModalProps> = ({
  onClose,
  children,
  isLoading,
  isDisabled
}): JSX.Element => {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div
        className="justify-center flex overflow-x-hidden overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none"
        data-testid={testConstants.MODAL_BACKGROUND}
      >
        <div className="relative mx-auto my-auto w-[420px]">
          <span ref={modalRef}>
            {/*content*/}
            <div className="border my-[100px] border-solid border-[rgba(0,_0,_0,_0.3)] rounded-sm shadow-[rgba(0,_0,_0,_0.14)_0px_5px_15px_4px] relative flex flex-col bg-white ">
              {/*body*/}
              <div className="relative mx-8 mt-8 flex-auto">{children}</div>
              {/*footer*/}
              <div className="flex items-center justify-end m-8 mb-10">
                <Button
                  className=" px-6 py-2 mr-2 self-center rounded-[0.25rem] w-fit text-[14px] text-primary font-[600] font-roboto hover:bg-gray-200 hover:no-underline"
                  variant="text"
                  type="button"
                  onClick={onClose}
                  data-testid={testConstants.MODAL_CANCEL_BUTTON}
                >
                  {t(translationConstants.CANCEL)}
                </Button>
                <Button
                  className={`px-6 py-2 self-center bg-primary rounded-[0.25rem] w-fit text-white text-[16px] font-[650] font-roboto hover:bg-[#286090] ${isLoading ? 'hover:bg-primary cursor-default' : ''}  ${isDisabled ? 'bg-gray-200 hover:bg-gray-200 cursor-default text-light-gray border border-gray-500' : ''}`}
                  variant="filled"
                  type="submit"
                  disabled={isLoading && isDisabled}
                  data-testid={testConstants.MODAL_SAVE_BUTTON}
                >
                  {isLoading && !isDisabled ? (
                    <CgSpinner className="animate-spin" size={24} />
                  ) : (
                    t(translationConstants.SAVE)
                  )}
                </Button>
              </div>
            </div>
          </span>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black" />
    </div>
  );
};
