import { FieldValue } from '../hooks/useValidateForm';

export type CreateAccountRequestBody = {
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  password: string;
  confirmPassword: string;
  termsAccepted: boolean;
  bb_preferred_language: string;
};

export type CreateAccountResponse = {
  statusCode: number;
  body: CreateAccountOKResponseBody | CreateAccountErrorResponseBody;
};

export type CreateAccountOKResponseBody = {
  email: string;
  clientId: string;
  loginPath: string;
};

type CreateAccountErrorResponseBody = {
  errorMessage: string;
  errorCode: string;
  timestamp: string;
  traceId: string;
};

const baseUrl = `https://accounts-api.${process.env.REACT_APP_DOMAIN}/account`;

const baseHeaders = {
  'content-type': 'application/json'
};

export const createAccount = async (formData: FieldValue, preferredLanguage: string) => {
  const body: CreateAccountRequestBody = {
    ...formData,
    bb_preferred_language: preferredLanguage,
    termsAccepted: true
  };

  const jsonBody = JSON.stringify(body);

  const response = await fetch(baseUrl, {
    method: 'POST',
    headers: baseHeaders,
    body: jsonBody
  });

  const responseData: CreateAccountResponse = {
    statusCode: response.status,
    body: await response.json()
  };

  return responseData;
};
