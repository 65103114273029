import { FC } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import * as testConstants from '../../utils/constants/testHTMLAttributeConstants';

type MessagePopUpProps = {
  message: string;
  onClose: () => void;
  color: string;
};

export const MessagePopUp: FC<MessagePopUpProps> = ({ message, onClose, color }) => {
  return (
    <div
      className={`flex gap-3 ${color} absolute top-10 right-10 p-5 rounded-md max-w-[350px]`}
      data-testid={testConstants.MESSAGE_POPUP}
    >
      <p className="font-roboto text-[16px] text-gray font-[400] leading-[1.50] tracking-[.27px]">
        {message}
      </p>
      <button className="flex" onClick={onClose}>
        <AiOutlineClose size={25} />
      </button>
    </div>
  );
};
