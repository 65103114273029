import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BluebeamBrands } from '@bluebeam/react-skyline-art';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { FiAlertTriangle } from 'react-icons/fi';
import { ProfileSettingsRow } from '../../components/ProfileSettingsRow';
import { Modal } from '../../components/Modals/Modal';
import { useValidateForm } from '../../hooks/useValidateForm';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useAuth } from '../../contexts/auth';
import { resendConfirmationEmail } from '../../services/manageAccount';
import { MessagePopUp } from '../../components/MessagePopUp';
import { RenderModalContent } from './RenderModalContent';
import { useHandleGetUserData } from '../../hooks/manageAccount/useHandleGetUserData';
import { useHandleLogout } from '../../hooks/manageAccount/useHandleLogout';
import { useHandlePopUp } from '../../hooks/useHandlePopUp';
import { useHandleModal } from '../../hooks/manageAccount/useHandleModal';
import { Loading } from '../../components/Loading';

import * as formConstants from '../../utils/constants/createAccountFormConstants';
import * as translationConstants from '../../utils/constants/i18nTranslationConstants';
import * as testConstants from '../../utils/constants/testHTMLAttributeConstants';
import * as rowNameConstants from '../../components/ProfileSettingsRow';

export const ManageAccount: FC = () => {
  const { isUserAuthenticated, signOut, signIn, bbid, accessToken, removeCredentials } = useAuth();
  const isSmallScreen = useMediaQuery('(max-width: 600px)');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    formValues,
    setFormValues,
    formErrors,
    handleChange,
    areSpecificFormFieldsValid,
    setCurrentPasswordNotCorrectError,
    setSSOFieldNotValidError,
    hasFormErrors,
    resetPasswordFields
  } = useValidateForm();

  const {
    profileValues,
    setProfileValues,
    isEmailConfirmed,
    isSSOAdmin,
    isSSO,
    preferredLanguage,
    setPreferredLanguage,
    isLoaded
  } = useHandleGetUserData({
    formValues,
    setFormValues,
    isUserAuthenticated,
    signIn,
    bbid,
    accessToken,
    removeCredentials
  });

  const { logoutRef, nameRef, showLogout, handleLogoutPopUp, handleLogout } = useHandleLogout({
    signOut
  });

  const {
    showPopUp,
    setShowPopUp,
    popUpMessage,
    setPopUpMessage,
    handleClosePopUp,
    popUpColor,
    setPopUpColor
  } = useHandlePopUp();

  const {
    showModal,
    handleChangeButtonClick,
    handleSubmit,
    handleCloseModal,
    isSaveButtonDisabled,
    isSaveButtonLoading,
    currentRow,
    ssoIDPTypeDropDownOptions,
    selectedSsoIDPTypeDropdownValue,
    selectedPreferredLanguageDropdownValue,
    handleSSOIdentityProviderDropdownChange,
    handlePreferredLanguageDropdownChange
  } = useHandleModal({
    formValues,
    setFormValues,
    formErrors,
    hasFormErrors,
    profileValues,
    setProfileValues,
    resetPasswordFields,
    areSpecificFormFieldsValid,
    setPopUpMessage,
    setShowPopUp,
    bbid,
    accessToken,
    setCurrentPasswordNotCorrectError,
    setSSOFieldNotValidError,
    setPopUpColor,
    preferredLanguage,
    setPreferredLanguage
  });

  const handleEmailUpdatedLink = async () => {
    try {
      const response = await resendConfirmationEmail(bbid!, accessToken!);
      if (!response.ok) {
        navigate('/error');
      }

      setPopUpMessage(t(translationConstants.WE_HAVE_SENT_YOU_UPDATED_LINK));
      setShowPopUp(true);
    } catch (error) {
      navigate('/error');
    }
  };

  const getLabelForLanguage = (languageCode: string) => {
    const option = translationConstants.LANGUAGE_OPTIONS.find(
      (option) => option.value === languageCode
    );
    return option ? option.label : 'Select a language';
  };

  // Flash empty page before routing to sign in if user is not authenticated
  // Show page only after user data is fetched
  return isUserAuthenticated() && isLoaded ? (
    <div className="max-w-[1440px] m-auto py-10 px-[4.5rem] sm:px-[2rem]">
      {/* header */}
      <ul className="relative">
        <li className="flex justify-between">
          {isSmallScreen ? (
            <BluebeamBrands.BluebeamBBrand
              size="32px"
              className="w-[40px] h-full block min-w-[40px]"
            />
          ) : (
            <BluebeamBrands.BluebeamAppBrand
              size="44px"
              className="w-[173px] h-full block  min-w-[173px]"
            />
          )}
          <button onClick={handleLogoutPopUp} className="sm:ml-3 sm:min-w-[80px]">
            <div ref={nameRef} className="flex items-center" data-testid={testConstants.NAME_DIV}>
              <p className="font-roboto text-[16px] font-[400] leading-[1.5] tracking-[.27] sm:truncate ">
                {`${profileValues[formConstants.FIRST_NAME_FIELD]} ${profileValues[formConstants.LAST_NAME_FIELD]}`}
              </p>
              <AiOutlineCaretDown size={'10px'} className="min-w-3" />
            </div>
          </button>
          {showLogout && (
            <div
              ref={logoutRef}
              className="absolute top-9 right-0 w-[170px] h-[42px] py-2 border border-gray-300 shadow-[rgba(0,0,0,0.15)_2px_2px_4px]"
            >
              <button onClick={handleLogout} className="w-full text-start hover:bg-blue-100">
                <p className="ml-4 text-gray font-roboto font-[400] text-[15px] ">
                  {t(translationConstants.LOGOUT)}
                </p>
              </button>
            </div>
          )}
        </li>
      </ul>

      {/* body */}
      <div className="flex justify-start w-full mt-[45px] md:flex-col">
        <p className="w-[25%] text-3xl font-roboto text-gray leading-[0.6] tracking-[.47px] text-[28px] font-bold">
          {t(translationConstants.SETTINGS)}
        </p>
        <div className="w-[50%] lg:w-full md:mt-10">
          {isSSO && (
            <div className="flex flex-row gap-[5px] mb-[10px]">
              <FiAlertTriangle className=" w-[18px] min-w-[18px] min-h-[18px] h-[18px] mt-[5px] text-[#7a7979] sm:w-[40px] sm:h-[40px] sm:mt-0 sm:p-0" />
              <p className="font-roboto text-[14px] font-[400] text-[#7a7979]">
                {t(translationConstants.SSO_CONTACT_ADMINISTRATOR)}
              </p>
            </div>
          )}
          <div className="border-b">
            <ProfileSettingsRow
              name={t(translationConstants.NAME)}
              value={`${profileValues[formConstants.FIRST_NAME_FIELD]} ${profileValues[formConstants.LAST_NAME_FIELD]}`}
              handleChangeButtonClick={() =>
                handleChangeButtonClick(rowNameConstants.NAME_SETTINGS_ROW)
              }
              changeButtonTestId={testConstants.CHANGE_NAME_BUTTON}
              isSSO={isSSO}
            />
          </div>
          <div className="border-b mt-5">
            <ProfileSettingsRow
              name={t(translationConstants.DISPLAY_NAME_NO_BLUEBEAM)}
              value={profileValues[formConstants.DISPLAY_NAME_FIELD]}
              handleChangeButtonClick={() =>
                handleChangeButtonClick(rowNameConstants.DISPLAY_NAME_SETTINGS_ROW)
              }
              changeButtonTestId={testConstants.CHANGE_DISPLAY_NAME_BUTTON}
            />
          </div>
          <div className="border-b mt-5">
            <ProfileSettingsRow
              name={t(translationConstants.EMAIL)}
              value={profileValues[formConstants.EMAIL_FIELD]}
              handleChangeButtonClick={() =>
                handleChangeButtonClick(rowNameConstants.EMAIL_SETTINGS_ROW)
              }
              changeButtonTestId={testConstants.CHANGE_EMAIL_BUTTON}
              isEmailConfirmed={isEmailConfirmed}
              isSSO={isSSO}
              handleEmailUpdatedLinkButtonClick={handleEmailUpdatedLink}
            />
          </div>
          <div className="border-b mt-5">
            <ProfileSettingsRow
              name={t(translationConstants.PASSWORD)}
              value="**************"
              handleChangeButtonClick={() =>
                handleChangeButtonClick(rowNameConstants.PASSWORD_SETTINGS_ROW)
              }
              changeButtonTestId={testConstants.CHANGE_PASSWORD_BUTTON}
              isEmailConfirmed={isEmailConfirmed}
              isSSO={isSSO}
            />
          </div>
          {isSSOAdmin && (
            <div className="border-b mt-5">
              <ProfileSettingsRow
                name={t(translationConstants.SSO_PROVIDER)}
                value=""
                handleChangeButtonClick={() =>
                  handleChangeButtonClick(rowNameConstants.SSO_IDENTITY_PROVIDER_ROW)
                }
                changeButtonTestId={testConstants.CHANGE_SSO_IDENTITY_PROVIDER_BUTTON}
              />
            </div>
          )}
          <div className="mt-5">
            <ProfileSettingsRow
              name={t(translationConstants.PREFERRED_LANGUAGE)}
              value={getLabelForLanguage(selectedPreferredLanguageDropdownValue)}
              handleChangeButtonClick={() =>
                handleChangeButtonClick(rowNameConstants.BB_PREFERRED_LANGUAGE_ROW)
              }
              changeButtonTestId={testConstants.CHANGE_PREFERRED_LANGUAGE_BUTTON}
            />
            <div className="flex flex-row gap-[5px] mb-[10px]">
              <p className="font-roboto text-[14px] font-[400] text-[#7a7979]">
                {t(translationConstants.BLUEBEAM_HONORING_LANGUAGE_SELECTION)}
              </p>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <form onSubmit={handleSubmit} className="flex flex-col w-full">
          <Modal
            onClose={() => handleCloseModal()}
            isDisabled={isSaveButtonDisabled}
            isLoading={isSaveButtonLoading}
          >
            <RenderModalContent
              currentRow={currentRow!}
              formValues={formValues}
              formErrors={formErrors}
              handleChange={handleChange}
              ssoIDPTypeDropDownOptions={ssoIDPTypeDropDownOptions}
              preferredLanguageDropDownOptions={translationConstants.LANGUAGE_OPTIONS}
              selectedSSOIDPTypeDropdownValue={selectedSsoIDPTypeDropdownValue}
              selectedPreferredDropDownValue={selectedPreferredLanguageDropdownValue}
              handleSSOIdentityProviderDropdownChange={handleSSOIdentityProviderDropdownChange}
              handlePreferredLanguageDropdownChange={handlePreferredLanguageDropdownChange}
            />
          </Modal>
        </form>
      )}
      {showPopUp && (
        <MessagePopUp message={popUpMessage} onClose={handleClosePopUp} color={popUpColor} />
      )}
    </div>
  ) : (
    <Loading />
  );
};
