import { FC } from 'react';
import { Input } from '@bluebeam/react-skyline-input';

type InputProps = {
  placeholder?: string;
  type?: string;
  value?: string | number | readonly string[] | undefined;
  hasError?: boolean;
  isValid?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  isDisabled?: boolean;
  testId?: string;
};

export const FormInput: FC<InputProps> = ({
  placeholder,
  type = 'text',
  value,
  hasError,
  isValid,
  onChange,
  testId,
  isDisabled
}) => {
  const getBorderColorClass = () => {
    if (hasError) {
      return 'border-2 border-danger';
    }

    if (isValid) {
      return 'border-2 border-success';
    }

    if (isDisabled) {
      return 'border-2 border-gray cursor-not-allowed';
    }

    return 'border border-neutral';
  };

  return (
    <Input
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      className={`${getBorderColorClass()} mb-[4px] rounded-[0.25rem] font-medium p-3`}
      disabled={isDisabled}
      data-testid={testId}
    ></Input>
  );
};
