import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { usePreferredLanguage } from '../../hooks/usePreferredLanguage';
import { GenericMessageContainer } from '../../components/GenericMessageContainer';

import * as translationConstants from '../../utils/constants/i18nTranslationConstants';

export const EmailVerified: FC = () => {
  const { t } = useTranslation();
  usePreferredLanguage();

  return (
    <GenericMessageContainer title={t(translationConstants.THANK_YOU)}>
      <div className="p-7 pt-0">
        <p className="text-[14px] font-[450] pb-[20px] text-gray">
          {t(translationConstants.CONFIRM_EMAIL_SUCCESS)}
        </p>
      </div>
    </GenericMessageContainer>
  );
};
