import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateCodeChallenge, generateCodeVerifier, generateState } from '../utils/auth';
import {
  CLIENT_ID,
  REDIRECT_URI,
  getAuthEndpoints,
  refreshTokensRequest,
  signInRequest
} from '../services/auth';

interface AuthContextProps {
  accessToken: string | null;
  idToken: string | undefined;
  bbid: string | undefined;
  refreshToken: string | undefined;
  isUserAuthenticated: () => boolean;
  signIn: () => void;
  signOut: () => void;
  removeCredentials: () => void;
  handleSignInCallback: () => Promise<void>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);
const RESPONSE_TYPE = 'code';
const RESPONSE_MODE = 'fragment';
const SCOPE = 'openid profile email offline_access';
const LOGOUT_URI = `${window.location.origin}/logout`;

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [idToken, setIdToken] = useState<string | undefined>(undefined);
  const [bbid, setBbid] = useState<string | undefined>(undefined);
  const [refreshToken, setRefreshToken] = useState<string | undefined>(undefined);
  const [expiryTime, setExpiryTime] = useState<number | null>(null);

  const navigate = useNavigate();

  const isUserAuthenticated = (): boolean => {
    const currentTime = Math.floor(Date.now() / 1000);
    return !!accessToken && !!idToken && !!bbid && expiryTime !== null && currentTime < expiryTime;
  };

  const signIn = async () => {
    try {
      const { AUTHORIZATION_URL } = await getAuthEndpoints();

      // Generate code verifier and state
      const codeVerifier = generateCodeVerifier();
      const codeChallenge = await generateCodeChallenge(codeVerifier);
      const state = generateState();

      // Store the code verifier and state in the session storage because they will be lost after redirect to Okta if stored in context
      sessionStorage.setItem('code_verifier', codeVerifier);
      sessionStorage.setItem('state', state);

      const encodedScope = SCOPE.replace(/ /g, '+');

      // Construct the authorization URL with PKCE parameters
      const authUrl = `${AUTHORIZATION_URL}?client_id=${CLIENT_ID}&code_challenge=${codeChallenge}&code_challenge_method=S256&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&response_mode=${RESPONSE_MODE}&response_type=${RESPONSE_TYPE}&scope=${encodedScope}&state=${state}`;

      window.location.href = authUrl;
    } catch (error) {
      navigate('/error');
    }
  };

  const handleSignInCallback = async () => {
    const urlParams = new URLSearchParams(window.location.hash.substring(1));
    const code = urlParams.get('code');
    const urlState = urlParams.get('state');

    const codeVerifier = sessionStorage.getItem('code_verifier');
    const state = sessionStorage.getItem('state');

    sessionStorage.removeItem('code_verifier');
    sessionStorage.removeItem('state');

    if (!codeVerifier || state !== urlState) {
      signIn();
      return;
    }

    try {
      const data = await signInRequest(code!, codeVerifier!);

      // Store tokens in state
      setAccessToken(data.access_token);
      setIdToken(data.id_token);
      setRefreshToken(data.refresh_token);

      // Store the expiry time
      const currentTime = Math.floor(Date.now() / 1000);
      setExpiryTime(currentTime + data.expires_in);

      // Extract bbid from ID token
      const idTokenPayload = JSON.parse(atob(data.id_token.split('.')[1]));
      setBbid(idTokenPayload.bbid);

      navigate('/manageaccount', { replace: true });
    } catch (error) {
      navigate('/error');
    }
  };

  const refreshTokens = async () => {
    if (refreshToken) {
      try {
        const data = await refreshTokensRequest(refreshToken);

        setAccessToken(data.access_token);
        setIdToken(data.id_token);
        setRefreshToken(data.refresh_token);

        // Update the expiry time
        const currentTime = Math.floor(Date.now() / 1000);
        setExpiryTime(currentTime + data.expires_in);
      } catch (error) {
        removeCredentials();
        signIn(); // Reauthenticate if refresh fails
      }
    }
  };

  const signOut = async () => {
    if (idToken) {
      try {
        const { END_SESSION_URL } = await getAuthEndpoints();

        const signOutUrl =
          END_SESSION_URL +
          '?' +
          new URLSearchParams({ id_token_hint: idToken!, post_logout_redirect_uri: LOGOUT_URI });

        removeCredentials();

        window.location.href = signOutUrl;
      } catch (error) {
        navigate('/error');
      }
    } else {
      removeCredentials();
      navigate('/error');
    }
  };

  const removeCredentials = () => {
    setAccessToken(null);
    setBbid(undefined);
    setIdToken(undefined);
    setExpiryTime(null);
    setRefreshToken(undefined);
  };

  useEffect(() => {
    const interval = setInterval(
      () => {
        refreshTokens();
      },
      45 * 60 * 1000
    ); // Refresh tokens every 45 minutes

    return () => clearInterval(interval);
  }, [refreshToken]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        idToken,
        bbid,
        refreshToken,
        isUserAuthenticated,
        signIn,
        signOut,
        removeCredentials,
        handleSignInCallback
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
