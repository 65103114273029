import { FC } from 'react';

type FormTitleProps = {
  title: string;
  testId?: string;
};

export const FormTitle: FC<FormTitleProps> = ({ title, testId }) => {
  return (
    <h2
      className="mt-[15px] mb-[40px] font-[700] font-roboto text-[16px] leading-[1.30px]"
      data-testid={testId}
    >
      {title}
    </h2>
  );
};
