import { FC } from 'react';
import { Button } from '@bluebeam/react-skyline-button';

import * as testConstants from '../../../utils/constants/testHTMLAttributeConstants';

type ModalProps = {
  onClose: () => void;
  onAccept: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  isAcceptTOSButtonDisabled: boolean;
};

export const TermsOfServiceModal: FC<ModalProps> = ({
  onClose,
  onAccept,
  isAcceptTOSButtonDisabled
}): JSX.Element => {
  return (
    <div>
      <div
        className="justify-center flex overflow-x-hidden overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none"
        data-testid={testConstants.TERMS_OF_USE_MODAL}
        onClick={onClose}
      >
        <div className="relative w-auto mt-7 mx-auto">
          {/*content*/}
          <div
            onClick={(e) => e.stopPropagation()}
            className="border border-solid border-[rgba(0,_0,_0,_0.6)] rounded-md shadow-[rgba(0,_0,_0,_0.24)_0px_5px_15px_4px] relative flex flex-col w-full bg-white "
          >
            {/*header*/}
            <div className="flex flex-col items-start justify-between pt-2 pb-3 border-b border-solid border-blueGray-200 rounded-t">
              <div className="w-full">
                <button
                  className="p-1 pr-2 ml-auto bg-transparent text-[#b8b8b8] border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none hover:text-light-gray"
                  onClick={onClose}
                >
                  <span
                    className=" h-6 w-6 text-[22px] block"
                    data-testid={testConstants.MODAL_CLOSE_BUTTON}
                  >
                    ×
                  </span>
                </button>
              </div>
            </div>
            {/*body*/}
            <div className="relative p-4 mb-2 flex-auto">
              <div className="max-w-[600px]">
                <h1 className="mt-[15px] pb-[20px] mb-[20px] text-[36px] font-[450] leading-[1.1] border-b border-solid border-blueGray-200">
                  Bluebeam’s General Services and Software Terms of Use
                </h1>
                {/* TODO: Get TOS from external source if possible */}
                <p className="font-[450] text-[14px] leading-[1.42]">
                  The Order regardless of form under which this Bluebeam Software was purchased and
                  Bluebeam’s General Terms and Conditions of Use found at
                  <a
                    href="https://www.bluebeam.com/Legal"
                    target="_blank"
                    className="mx-1 text-primary hover:underline hover:text-[#286090]"
                  >
                    https://www.bluebeam.com/Legal
                  </a>
                  plus the applicable Additional Terms constitute the contract governing your access
                  to and use of Bluebeam’s Software (“Agreement”). By using Bluebeam’s Software You
                  are agreeing to abide by the Agreement. If you have entered into another
                  negotiated agreement with Bluebeam integrated into an Order for this Bluebeam
                  Software, then the terms of that negotiated agreement will control where it
                  conflicts with the Agreement referenced above.
                </p>
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
              <Button
                className=" px-6 py-2 mr-2 self-center rounded-[0.25rem] w-fit text-[16px] font-[600] uppercase hover:bg-gray-200"
                variant="outline"
                type="button"
                onClick={onClose}
                data-testid={testConstants.MODAL_CANCEL_BUTTON}
              >
                Cancel
              </Button>
              <Button
                className={`px-6 py-2 self-center bg-primary rounded-[0.25rem] w-fit text-white text-[16px] font-[650] uppercase hover:bg-[#286090] ${isAcceptTOSButtonDisabled ? 'cursor-not-allowed' : ''}`}
                variant="filled"
                type="button"
                onClick={onAccept}
                disabled={isAcceptTOSButtonDisabled}
              >
                I accept
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
};
