import { FC } from 'react';
import { BluebeamBrands } from '@bluebeam/react-skyline-art';

import * as testConstants from '../../utils/constants/testHTMLAttributeConstants';

type GenericMessageContainerProps = {
  title?: string;
  children?: React.ReactNode;
  links?: React.ReactNode;
};

export const GenericMessageContainer: FC<GenericMessageContainerProps> = ({
  title,
  children,
  links
}) => {
  return (
    <div className="xs:h-full xs:overflow-hidden">
      <div className="bg-white mt-[50px] mx-auto border border-gray-300 shadow-[rgba(0,0,0,0.15)_2px_2px_4px] w-[420px] xs:w-full xs:mx-0 xs:mt-0 xs:shadow-none">
        <div className="py-[22px] px-[28px]">
          <BluebeamBrands.BluebeamAppBrand
            size="44px"
            className="h-[50px] w-[161px]"
            data-testid={testConstants.BB_LOGO}
          />
          <h2 className="mt-[10px] font-roboto text-[28px] font-[650] leading-[30px] text-gray">
            {title}
          </h2>
        </div>
        {children}
      </div>
      <div className="mx-auto border border-t-0 bg-gray-100 border-gray-300 shadow-[rgba(0,0,0,0.15)_2px_2px_4px] w-[420px] xs:w-full xs:h-full xs:mx-0 xs:border-none xs:shadow-none">
        {links && links}
      </div>
    </div>
  );
};
