import { FC, useState } from 'react';

type TooltipProps = {
  text: string;
  textOnHover: string;
};

export const Tooltip: FC<TooltipProps> = ({ textOnHover, text }): JSX.Element => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleHover = () => {
    setIsHovered((prevState) => !prevState);
  };

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
        className="inline-block cursor-pointer border-b-1 border-b-black border-b-solid mb-[2px]"
      >
        {text}
      </div>
      {isHovered && (
        <div className="absolute bottom-[130%] left-1/2 min-w-[170px] transform -translate-x-1/2 text-[15px] font-medium bg-black text-white p-2.5 rounded-sm opacity-70">
          {textOnHover}
          <svg
            className="absolute text-black h-2 w-full left-0 top-full"
            x="0px"
            y="0px"
            viewBox="0 0 255 255"
            xmlSpace="preserve"
          >
            <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
          </svg>
        </div>
      )}
    </div>
  );
};
